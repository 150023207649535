<template>
    <div class="rawMaterialAnalysisReport">
        <!-- <div class="filter-box">
            <div class="btn-box">
                <button class="btn" @click="$emit('skipBack')">返回</button>
            </div>
            <div class="right-bnts">
                <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
            </div>
        </div> -->
        <div class="panel-box top-info-box">
            <el-row>
                <el-col :xs="12" :sm="8" :md="6">
                    <span class="lable-text">营业日期：{{data?.Rpt_Date}}</span>
                </el-col>
                <el-col :xs="12" :sm="8" :md="6">
                    <span class="lable-text">餐厅：{{data?.Eat_RoomName}}</span>
                </el-col>
                <el-col :xs="12" :sm="8" :md="6">
                    <span class="lable-text">菜品：{{data?.Eat_XFName}}</span>
                </el-col>
            </el-row>
            <div class="right-bnts">
                <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
            </div>
            <!-- <div class="btn-box">
                <button class="btn" @click="$emit('skipBack')">返回</button>
            </div> -->
        </div>
        <div class="panel-box table-box grey-table" ref="tableBox">
            <el-table ref="tableEl" :data="tableList"  style="width: 100%;height:100%"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                 show-summary
                :summary-method="getSummaries"
                >
                <el-table-column label="序号" type="index" width="50" />
                <el-table-column prop="Item_Code" label="原料编码" align="left" width="90"></el-table-column>
                <el-table-column prop="Item_Name" label="原料名称" align="left" width="90"></el-table-column>
                <el-table-column prop="BaseUnit" label="基本单位" width="70"> </el-table-column>
                <el-table-column prop="Item_GG" label="规格" width="52"> </el-table-column>
                <el-table-column label="成本参数">
                    <el-table-column prop="CostUnit" label="成本单位" min-width="80"></el-table-column>
                    <el-table-column prop="Rate" label="出料率%" align="right" min-width="80" data-format="number">
                        <template #default="scope">{{priceFormat(scope.rwo,scope,scope.row.Rate)}}%</template>
                    </el-table-column>
                    <el-table-column prop="Profit_Amount" label="毛料用量" align="right" min-width="80" :formatter="amountFormat" data-format="number"></el-table-column>
                    <el-table-column prop="BaseUnitPrice" label="基本单位单价" align="right" min-width="85" :formatter="priceFormat" data-format="number"></el-table-column>
                    <el-table-column prop="CostMoney" label="成本金额" align="right" min-width="80" :formatter="priceFormat" data-format="number"></el-table-column>
                </el-table-column>
            </el-table>
        </div>
        <div class="bottom-box" v-if="false">
            <div class="flex-box">
                <div class="li">
                    <div class="number">39</div>
                    <div class="name">售价</div>
                </div>
                <div class="li">
                    <div class="number">50%</div>
                    <div class="name">标准毛利率</div>
                </div>
                <div class="li">
                    <div class="number">54%<span class="asc">↑4</span></div>
                    <div class="name">折前毛利率</div>
                </div>
                <div class="li">
                    <div class="number">38%<span class="desc">↓2</span></div>
                    <div class="name">折后毛利率</div>
                </div>
                <div class="li">
                    <div class="number">38%<span class="desc">↓2</span></div>
                    <div class="name">实收毛利率</div>
                </div>
                <div class="li">
                    <div class="number">36%<span class="desc">↓2</span></div>
                    <div class="name">去税毛利率</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FileSaver from 'file-saver'
import XLSX from 'xlsx'

/**菜品原材料分析表 */
export default {
    name:"reportBillCostDetail",
    props:{
        data:{
            type:Object,
            default:null
        }
    },
    data(){
        return {
            tableList:[]
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        this.loadData();
        this.$nextTick(()=>{
        })
    },
    methods:{
         /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /*毛利率格式 截取4位小数 */
        amountFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(4));
            }
        },
        //合计
        getSummaries({ columns, data }){
            const sums= [];
            const total={
                CostMoney:0,
            };
            this.tableList?.forEach(it=>{
                total.CostMoney += it.CostMoney;
            })
            columns.forEach((column, index) => {
                if(total[column.property]!=undefined){
                    sums[index]=parseFloat(total[column.property].toFixed(2));
                }else{
                    sums[index]="";
                }
            })
            sums[0]="合计";
            return sums;
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            this.$excelCommon.elTableToExcel({
                elTable:this.$refs.tableEl,
                titleName:"菜品原材料分析表",
                list:this.tableList,
                headArr:[
                    [
                        {value:'营业日期：'+this.data?.Rpt_Date,col:3},
                        {value:'餐厅：'+this.data?.Eat_RoomName,col:3},
                        {value:'菜品：'+this.data?.Eat_XFName,col:3},
                    ],
                ]
            })

        },
        /**查询 */
        loadData(){
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                Eat_XFBMID:this.data.Eat_XFBMID,
                Rpt_Date:this.data.Rpt_Date,
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            this.$httpAES.post("Bestech.CloudPos.FoodMaterialReport",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList=d.ResponseBody;
                }else{
                    this.tableList=[];
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        },
    }
}
</script>

<style lang="scss">
    @import "./rawMaterialAnalysisReport.scss"
</style>